import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import * as Icons from '@element-plus/icons-vue';

const app = createApp(App); // 创建 Vue 应用实例

// 注册 Element Plus 组件库
app.use(ElementPlus);
// 遍历所有的图标并注册为全局组件
Object.keys(Icons).forEach((key) => {
  app.component(key, Icons[key]);
});
// 使用路由
app.use(router);
// 挂载应用
app.mount('#app');