import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/home.vue'

const routes = [
  
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login.vue')
  },
  {
    path: '/',
    name: 'home',
    component: HomeView,
    redirect: '/console', 
    children :[
      {
        path: '/console',
        component: () => import("../views/recruitmentManagement/aiConsole.vue"),
      },
      {
        path: '/position',
        component: () => import("../views/recruitmentManagement/position.vue"),
      },
      {
        path: '/resumeList',
        component: () => import("../views/talents/resumeList.vue"),
      },
      {
        path: '/engine',
        component: () => import("../views/talents/engine.vue"),
      },
      {
        path: '/chatbotConfig',
        component: () => import("../views/aiConfig/chatbotConfig.vue"),
      },
      {
        path: '/chatbotScheme',
        component: () => import("../views/aiConfig/chatbotScheme.vue"),
      },
      
    ],
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
