<template>
  <div class="main">
    <div class="left">
      <div class="menu_image">
        <img src="/icon/openai.png" /><img src="/icon/openai.png" />
      </div>

      <div
        class="menu_array"
        v-for="(item, index) in menuArray"
        :key="index"
        :index="String(item.id)"
      >
        <div class="menu_title">{{ item.name }}</div>
        <div
          class="menu_child"
          v-for="(item2, index2) in item.child"
          :key="index2"
          :index="String(item2.id)"
          :class="{ 'is-active': activeMenu === item2.id }"
          @click="goToMenu(item2.link,item2.id)"
        >
          <div class="menu_name">
            <div class="child_icon"><img :src="item2.icon" alt="" /></div>
            <div class="child_text">{{ item2.name }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="right">
      <router-view></router-view>
    </div>
  </div>
</template>
  
  <script>
import { ref,watch,onMounted } from "vue";
import { useRouter,useRoute } from "vue-router";
import { ElMessage } from "element-plus";

export default {
  setup() {
    const router = useRouter();
    const route = useRoute();
    const isCollapse = ref(false); // 默认不折叠
    const menuArray = ref([
      {
        id: 1,
        parent: 0,
        name: "智能招聘管理",
        icon: "",
        child: [
          {
            id: 2,
            parent: 1,
            name: "AI助手工作台",
            icon: "/icon/yibiaopan.png",
            icon2: "/icon/yibiaopan.png",
            link: "/console",
          },
          {
            id: 3,
            parent: 1,
            name: "职位管理",
            icon: "/icon/zhiwei.png",
            link: "/position",
          },
        ],
      },
      {
        id: 4,
        parent: 0,
        name: "人才库",
        icon: "",
        child: [
          {
            id: 5,
            parent: 4,
            name: "简历管理",
            icon: "/icon/jianli.png",
            link: "/resumeList",
          },
          {
            id: 6,
            parent: 4,
            name: "智能匹配引擎",
            icon: "/icon/pipei.png",
            link: "/engine",
          },
        ],
      },
      {
        id: 7,
        parent: 0,
        name: "AI招聘助手配置",
        icon: "",
        child: [
          {
            id: 8,
            parent: 7,
            name: "chatbot基础设置",
            icon: "/icon/chatbot.png",
            link: "/chatbotConfig",
          },
          {
            id: 9,
            parent: 7,
            name: "chatbot方案配置",
            icon: "/icon/fangan.png",
            link: "/chatbotScheme",
          },
          {
            id: 10,
            parent: 7,
            name: "多账号登录管理",
            icon: "/icon/duozhanghao.png",
            link: "",
          },
          {
            id: 11,
            parent: 7,
            name: "模型训练广场",
            icon: "/icon/xunlian.png",
            link: "",
          },
        ],
      },
      {
        id: 12,
        parent: 0,
        name: "系统设置",
        icon: "",
        child: [
          {
            id: 13,
            parent: 12,
            name: "用户管理",
            icon: "/icon/user.png",
            link: "",
          },
          {
            id: 13,
            parent: 12,
            name: "充值管理",
            icon: "/icon/chongzhi.png",
            link: "",
          },
        ],
      },
    ]);
    const activeMenu = ref(null); // 当前激活的菜单项

    const goToMenu = (path,id) => {
      if (path) {
        activeMenu.value = id; // 设置当前激活的菜单项
        router.push(path);
      } else {
        ElMessage({
          message: "开发者未开放此权限，请联系开发者",
          type: "error",
          plain: true,
        });
      }
    };

    // 监听路由变化，更新激活的菜单项
    watch(() => route.path, (newPath) => {
      activeMenu.value = findMenuId(newPath);
    });

    // 初始化激活的菜单项
    onMounted(() => {
      const token = localStorage.getItem("token");
      console.log(token);
      if(!token){
        ElMessage({
          message: "请先登录",
          type: "error",
          plain: true,
        });
        router.push('/login');
        return;
      }
      activeMenu.value = findMenuId(route.path);
    });

    // 根据路径找到菜单项的 id
    const findMenuId = (path) => {
      for (const item of menuArray.value) {
        for (const childItem of item.child) {
          if (childItem.link === path) {
            return childItem.id;
          }
        }
      }
      return null;
    };

    return { isCollapse, menuArray, goToMenu, activeMenu };
  },
};
</script>
  
<style lang="scss" scoped>
.menu_child {
  transition: background-color 0.3s ease; // 加入过渡效果
  border-radius: 10px;
  cursor: pointer;
  // 移除 :hover 伪类，因为我们将使用 active 状态来应用样式
}

.is-active {
  background-color: #e8e8ff; // 激活状态的背景颜色
  border-radius: 10px;
}

.main {
  width: 100%;
  height: 100vh;
  display: flex;
  //   border: 1px red solid;
}

.left {
  width: 200px;
  height: 100%;
  background: #ffffff;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1); /* 添加右侧阴影 */
  .menu_image {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100px;
    border-bottom: 1px #ccc solid;
    // border: 1px solid blue;
    img {
      width: 70px;
      height: 70px;
    }
  }
}

.menu_array {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  //   border-top: 1px #000 solid;
  border-bottom: 1px #ccc solid;
  .menu_title {
    width: 100%;
    font-size: 12px;
    color: #999;
    margin: 10px 0 10px 10px;
  }

  .menu_child {
    width: 100%;
    margin: 5px 10px;
    padding: 10px 0;
    transition: background-color 0.3s ease; // 加入过渡效果
    border-radius: 10px;

    &:hover {
      background-color: #e8e8ff; // 鼠标悬停时改变底部背景颜色
      border-radius: 10px;
    }
    .menu_name {
      cursor: pointer;
      width: 100%;
      display: flex;
      align-items: center;
      margin-left: 10px;
      // border: 1px green solid;
      .child_icon {
        width: 30px;
        height: 30px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .child_text {
        margin-left: 10px;
        font-size: 14px;
        color: #535763;
      }
    }
  }
}

.right {
  width: calc(100% - 200px);
  height: 100vh;
//   border: green 1px solid;
}
</style>
  